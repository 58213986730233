import general from './general';

const index = {
    init: function () {
        document.addEventListener('DOMContentLoaded', () => {
            this.bestOffersToggle();
            this.officeSlider();
            this.catalogSlider();
            this.testimonials();
            setTimeout(this.initContactsMap, 1000)
        })
    },
    officeSlider: function () {
        const office__slider = $(".office__slider");
        if (office__slider.length === 0) return;

        office__slider.flickity({
            cellAlign: "center",
            friction: 0.3,
            prevNextButtons: false,
            pageDots: false,
            on: {
                change: function() {
                    sliderChange();
                    $(".office__slider").find(".fb_loaded").removeClass("fb_loaded");
                    fast_background.update();
                },
                ready: function() {
                    if ($(window).width() > 767) {
                        setTimeout(function() {
                            $(".office__slider").flickity("select", 2);
                        });
                    }
                },
            },
        });

        function sliderChange() {
            const office__item_is_selected = $(".office__item.is-selected");
            var tag = office__item_is_selected.data("office");
            $(".office__item").removeClass("siblings-next siblings-prev");
            office__item_is_selected.next().addClass("siblings-next");
            office__item_is_selected.prev().addClass("siblings-prev");
            $('.location-nav button.office-tag').removeClass("active");

            var activeTag = $(`.location-nav button[data-office="${tag}"]`);
            var desc = activeTag.data("desc");

            activeTag.addClass('active');
            $('.office__subtitle').html(desc);
        }
        sliderChange();

        $(".office__button").on("click", function(event) {
            var index = $(".office__item.is-selected").index();
            if ($(this).hasClass("office__button_next")) {
                $(".office__slider").flickity("select", index + 1);
            } else if ($(this).hasClass("office__button_prev")) {
                $(".office__slider").flickity("select", index - 1);
            }
        });

        $('button.office-tag').on("click", function(event) {
            if ($(this).data("office") !== $('button.office-tag.active').data('office')) {
                $(".office__slider").flickity(
                    "select",
                    $(`.office__item[data-office="${$(this).data("office")}"]`).first().index()
                );
            }
        });
    },

    catalogSlider: function() {
        const catalog_carousel__slider = $(".catalog-carousel__slider");
        if (catalog_carousel__slider.length === 0) return;

        catalog_carousel__slider.flickity({
            cellAlign: "center",
            prevNextButtons: false,
            pageDots: false,
            wrapAround: true,
            freeScroll: true,
        });

        var sliderData = catalog_carousel__slider.data("flickity");
        sliderData.x = 0;

        function autoPlay() {
            sliderData.x = sliderData.x - 0.25;
            sliderData.settle(sliderData.x);
            let requestId = window.requestAnimationFrame(autoPlay);
        }

        autoPlay();
    },

    bestOffersToggle: function() {
        if ($('.offers-toggle').length === 0) return;

        $(document).on('click', '.offers-toggle__btn', function() {
            if ($(this).hasClass('active')) return;
            $('.offers-toggle__btn').removeClass('active');
            $(this).addClass('active');

            changeTypeRealty($(this).data('offers-type'), $(this).closest('.offers-toggle').data('url'));
        });

        function changeTypeRealty(type, url) {
            let wrapp = $('.best-offers');
            let container = $('#best-offers');

            $.ajax({
                url: url + '?type=' + type,
                beforeSend: () => {
                    wrapp.addClass('waiting');
                },
                success: (res) => {
                    wrapp.removeClass('waiting');

                    if (!res) {
                        container.hide();
                    } else {
                        container.css('display', 'block');
                    }

                    container.html(res);
                    fast_background.update();
                },
            });
        }
    },

    testimonials: function() {
        function switchTestimonials() {
            const data_testimonials = $("[data-testimonials]");
            data_testimonials.removeClass("show");
            $(`[data-testimonials=${$('[name="testimonials-tab"]:checked').val()}]`).addClass("show");
            data_testimonials.find(".scroll__container").scrollLeft(0);
            general.customScrollBar($("[data-testimonials].show"), $(window).width() < 768);
        }
        switchTestimonials();

        $('[name="testimonials-tab"]').on("change", function() {
            switchTestimonials();
        });
    },

    initContactsMap: function() {
        const container = document.getElementById('map-contacts');

        if (!container) return

        const map = new google.maps.Map(container, {
            zoom: 12,
            center: { lat: 55.74906171226733, lng: 37.540043232016565 },
            disableDefaultUI: true,
            gestureHandling: false,
            keyboardShortcuts: false,
            styles: [
                {
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#000a0d"
                        }
                    ]
                },
                {
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#757575"
                        }
                    ]
                },
                {
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "color": "#212121"
                        }
                    ]
                },
                {
                    "featureType": "administrative.country",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#9e9e9e"
                        }
                    ]
                },
                {
                    "featureType": "administrative.locality",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#bdbdbd"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#757575"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#141e21"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#616161"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "color": "#1b1b1b"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#151f22"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#8a8a8a"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#283235"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#596366"
                        }
                    ]
                },
                {
                    "featureType": "road.highway.controlled_access",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#4e4e4e"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#616161"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#757575"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#294851"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#3d3d3d"
                        }
                    ]
                }
            ]
        });
        const imageSize = $(window).width() > 767 ? {size: [90, 152], url: '/images/contact-dot.svg'}: {size: [53, 75], url: '/images/contact-dot_mobile.svg'};
        const image = {
            url: imageSize.url,
            size: new google.maps.Size(imageSize.size[0], imageSize.size[1]),
        }
        const markers = {}

        $('[data-coords]').each(function (i, element) {
            const coords = getCoordinates(element);
            const office = $(element).data('office');
            const isChecked = $('input[id="' + $(element).attr('for') + '"]').is(':checked')

            markers[office] = new google.maps.Marker({
                position: new google.maps.LatLng(coords[0], coords[1]),
                map: (isChecked) ? map : null,
                icon: image
            })
        })

        $(document).on('click', '[data-coords]', function (e) {
            // Текущий маркер
            const marker = markers[$(this).data('office')];
            // Скрываем все маркеры
            for (const office in markers) {
                markers[office].setMap(null);
            }
            // Скрываем все контакты
            $('[data-visible-for-office]').hide()
            // Показываем контакты выбранного офиса
            $(`[data-visible-for-office="${ $(this).data('office') }"]`).show()
            // Показываем маркер
            marker.setMap(map);
            // Перемещаемся в центр
            map.panTo(marker.getPosition());
        })

        // При инициализации устанавливаем центром выбранный маркер
        for (const office in markers) {
            const marker = markers[office]
            // Если маркеры присутствует на карте, то перемещаемся в центр
            if (marker.map) {
                map.setCenter(marker.getPosition());
            }
        }

        function getCoordinates(element) {
            return $(element).data('coords').split(',').map((coordinate) => coordinate.trim())
        }
    }
};

general.init();
index.init();
